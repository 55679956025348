import axios from 'axios'

export default {
  async fetch ({ commit, getters }) {
    const { items } = getters
    if (items.length) return
    const { data } = await axios.get('items')
    commit('SET_ITEMS', data.data)
    return true
  }
}
