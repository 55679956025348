import Vue from 'vue'

const helpers = {
  baseURL: process.env.VUE_APP_API_URL,
  image: path => {
    if (!path) return
    if (path[0] === '/') path = path.substring(1)
    return `${helpers.baseURL}/${path}`
  },
  file: path => {
    if (!path) return
    if (path[0] === '/') path = path.substring(1)
    return `${helpers.baseURL}/storage/${path}`
  },
  emailValidate: email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
    return re.test(String(email).toLowerCase())
  },

  cnpjValidate: cnpj => {
    if (!cnpj || cnpj.length !== 14) {
      return false
    } else if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false
    } else {
      let j = 5
      let k = 6
      let sum1 = 0
      let sum2 = 0

      for (let i = 0; i < 13; i++) {
        j = j === 1 ? 9 : j
        k = k === 1 ? 9 : k
        sum2 += cnpj[i] * k
        if (i < 12) sum1 += cnpj[i] * j
        k--
        j--
      }

      const digit1 = sum1 % 11 < 2 ? 0 : 11 - (sum1 % 11)
      const digit2 = sum2 % 11 < 2 ? 0 : 11 - (sum2 % 11)
      return (
        parseInt(cnpj[12]) === parseInt(digit1) ||
        parseInt(cnpj[13]) === parseInt(digit2)
      )
    }
  }
}

Vue.prototype.$help = helpers

export default helpers
