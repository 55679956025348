import axios from 'axios'

export default {
  async getData ({ commit, rootGetters }) {
    const { id } = rootGetters['user/user']
    const { data } = await axios.post('/financial/getBalanceAdmin', { id })
    const values = data.data

    const points = values.ballance_user || 0
    const collectors = values.ballance_collectors || 0
    const total = parseFloat(points) + parseFloat(collectors)

    commit('SET_TOTAL_BALANCE', total)
    commit('SET_CACHE_BALANCE', points)
    commit('SET_BALANCE_COLLECTORS', collectors)
  },
  async getGraphics ({ commit, rootGetters }) {
    const { id } = rootGetters['user/user']
    const { data } = await axios.post('/financial/getGraphics', { id })
    const values = data.data
    commit('SET_GRAPHICS_DATA', values)
  }
}
