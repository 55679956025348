import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

Vue.use(VueRouter)

routes.push({
  path: '*',
  name: 'error-404',
  component: () => import('@/views/error-404')
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'current',
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('access_token')
  const { path } = to
  const authPaths = ['/auth/login', '/auth/registro', '/auth/request', '/auth/reset']
  if (!token && !authPaths.includes(path)) return next('/auth/login')
  try {
    const { exp } = JSON.parse(atob(token.split('.')[1]))
    if (exp * 1000 < new Date().getTime()) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user')
      return next('/auth/login')
    }
    next()
  } catch (_) {
    return next()
  }
})

export default router
