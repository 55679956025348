class ViaCEP {
  async fetchZip (zip) {
    return await fetch('/cep' + zip + '/json', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
  }
}

export default new ViaCEP()
