export const routes = [
  {
    path: '/',
    component: () => import('@/views/layouts/main.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/index.vue')
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard.vue')
      },
      {
        path: '/coletas',
        name: 'collections',
        component: () => import('@/views/collections')
      },
      {
        path: '/materiais',
        name: 'materiais de coleta',
        component: () => import('@/views/collection-materials')
      },
      {
        path: '/materiais/agente-coleta/:id/:item',
        name: 'collectionAgent',
        component: () => import('@/views/collection-agent')
      },
      {
        path: '/sugestao',
        name: 'sugestão de materiais',
        component: () => import('@/views/suggestion-materials')
      },
      {
        path: '/material/:id',
        name: 'material-details',
        component: () => import('@/views/material-details')
      },
      {
        path: '/solicitar-coleta/:id',
        name: 'request-colletion',
        component: () =>
          import('@/views/material-details/request-colletion.vue')
      },
      {
        path: '/clientes',
        name: 'clients',
        component: () => import('@/views/clients')
      },
      {
        path: '/relatorios',
        name: 'reports',
        component: () => import('@/views/reports')
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/report')
      },
      {
        path: '/reportsshow/:id',
        name: 'reportshow',
        component: () => import('@/views/report/show')
      },
      {
        path: '/usuarios',
        name: 'collectors',
        component: () => import('@/views/collectors')
      },
      {
        path: '/extratos',
        name: 'extracts',
        component: () => import('@/views/extracts')
      },
      {
        path: '/details',
        name: 'details',
        component: () => import('@/views/details')
      },
      {
        path: '/creditos',
        name: 'credits',
        component: () => import('@/views/credits')
      },
      {
        path: '/acertos',
        name: 'hits',
        component: () => import('@/views/hits')
      },
      {
        path: '/transferencias',
        name: 'transfers',
        component: () => import('@/views/transfers')
      },
      {
        path: '/perfil',
        name: 'profile',
        component: () => import('@/views/profile')
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/layouts/auth.vue'),
    children: [
      {
        path: 'registro',
        component: () => import('@/views/auth/register/index.vue')
      },
      {
        path: 'login',
        component: () => import('@/views/auth/login/index.vue')
      },
      {
        path: 'request',
        component: () => import('@/views/auth/login/request_password.vue')
      },
      {
        path: 'reset',
        component: () => import('@/views/auth/login/reset_password.vue')
      }
    ]
  }
]
