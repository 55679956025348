const formater = val => {
  if (val === null || val === undefined || val === false) return val
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(parseFloat(val))
}

export default {
  totalBalance (state) {
    return formater(state.total_balance)
  },
  cashBalance (state) {
    return formater(state.cash_balance)
  },
  balanceCollectors (state) {
    return formater(state.balance_collectors)
  },
  graphicsData (state) {
    return state.graphics_data
  }
}
