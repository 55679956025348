export default {
  request (state) {
    return state.request
  },
  scheduled (state) {
    return state.scheduled
  },
  requested (state) {
    return state.requested
  },
  allItems (state) {
    const { request, scheduled, requested } = state
    return [...request, ...scheduled, ...requested]
  }
}
