import { get } from 'axios'

export default {
  async fetchItems ({ rootGetters, commit }) {
    const user = rootGetters['user/user']

    const url = `/itens/show/${user.id}`
    const { data } = await get(url)
    const { item, agendado, coleta } = data
    commit('SET_SCHEDULED', agendado)
    commit('SET_REQUESTED', coleta)
    commit('SET_REQUEST', item)
  }
}
