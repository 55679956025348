import axios from 'axios'

export default {
  async fetch ({ commit, getters }) {
    const { unities } = getters
    if (unities.length) return
    const { data } = await axios.get('unities')
    commit('SET_UNITIES', data)
    return true
  }
}
