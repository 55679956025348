export default {
  SET_REQUEST (state, request) {
    state.request = request
  },
  SET_SCHEDULED (state, scheduled) {
    state.scheduled = scheduled
  },
  SET_REQUESTED (state, requested) {
    state.requested = requested
  }
}
