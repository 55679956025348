import city from './city'
import closet from './closet'
import item from './item'
import province from './province'
import unity from './unity'
import user from './user'
import material from './material'
import suggestion from './suggestion'
import monetary from './monetary'

export default {
  city,
  closet,
  item,
  province,
  unity,
  user,
  material,
  suggestion,
  monetary
}
