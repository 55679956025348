import axios from 'axios'

export default {
  async fetch ({ commit, getters }) {
    const { provinces } = getters
    if (provinces.length) return
    const { data } = await axios.get('provinces')
    commit('SET_PROVINCES', data)
    return true
  }
}
