import axios from 'axios'
import ViaCep from '../../../models/ViaCep'

export default {
  async fetch ({ commit, getters }, pl = {}) {
    const { province, city } = pl
    if (!province && !city) return
    const { cities } = getters

    if (city && cities.map(c => c.id).includes(city)) return
    const url = `cities?province_id=${province}`
    const { data } = await axios.get(url)
    commit('SET_CITIES', data)
    return true
  },

  async getViaCEP ({ dispatch }, { zipCode, province }) {
    const data = await ViaCep.fetchZip(zipCode)
    if (data.error) return false
    const { bairro, complemento, logradouro, ibge } = data

    const cityData = await dispatch('getCity', ibge)

    return {
      district: bairro,
      complement: complemento,
      street: logradouro,
      city_id: cityData.id,
      province_id: cityData.province_id
    }
  },

  async getCity ({ dispatch }, ibge) {
    const url = `cities/ibge/${ibge}`
    const { data } = await axios.get(url)
    await dispatch('fetch', { province: data.province_id, city: data.id })
    return data
  }
}
