export default {
  SET_TOTAL_BALANCE (state, value) {
    state.total_balance = value
  },
  SET_CACHE_BALANCE (state, value) {
    state.cash_balance = value
  },
  SET_BALANCE_COLLECTORS (state, value) {
    state.balance_collectors = value
  },
  SET_GRAPHICS_DATA (state, value) {
    state.graphics_data = value
  }
}
