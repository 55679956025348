import axios from 'axios'

export default {
  async fetch ({ commit }, pl) {
    const search = pl || {}
    let url = '/collectors/materials'
    if (search) url = `${url}&search=${search}`
    const { data } = await axios.get(url)
    commit('SET_MATERIALS', data.data)
    return { page: data.current_page, pages: data.last_page }
  },

  async save (_, data) {
    try {
      const [id] = data.getAll('id')
      const url = id ? `collectors/material/user/${id}` : 'collectors/materials'
      const response = await axios.post(url, data)
      return { response: response.data, status: true }
    } catch (error) {
      return { response: error.response.data, status: false }
    }
  }
}
